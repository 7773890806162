class TooltipAdjuster {
  constructor(selector) {
    this.elements = document.querySelectorAll(selector);
    this.viewportWidth = window.innerWidth;
    this.init();
  }

  init() {
    this.elements.forEach(element => {
      const toggleButton = element.previousElementSibling;
      toggleButton.addEventListener("click", () => this.adjustTooltip(element));
    });
  }

  adjustTooltip(element) {
    // Evitar recalcular si ya fue ajustado
    if (element.dataset.adjusted) {
      return;
    }
    // Obtener las dimensiones del tooltip
    const tooltipRect = element.getBoundingClientRect();
    const roundRight = Math.round(tooltipRect.right + 8);
    // Ajustar si se sale
    if (roundRight > this.viewportWidth) {
      const excess = roundRight - this.viewportWidth;
      element.style.left = `-${excess}px`;
      element.dataset.adjusted = "true";
    }
  }
}

new TooltipAdjuster(".ag-js-tooltip-box");

class externalLinkTooltip {
  constructor(selector) {
    this.element = document.querySelector(selector);
    this.init();
  }
  init() {
    this.getPositionLink();
  }

  getExternalLinks() {
    const externalLinksnoTarget = document.querySelectorAll(
      '.ag-s01-rich-text_tooltip a[href^="https://"]:not([target="_blank"])'
    );
    const externalLinksTarget = document.querySelectorAll(
      '.ag-s01-rich-text_tooltip a[href^="https://"]:not([target="_blank"])'
    );
    const externalLinksnoTargetTwo = document.querySelectorAll(
      '.ag-s01-rich-text a[href^="https://"]:not([target="_blank"])'
    );
    const externalLinksTargetTwo = document.querySelectorAll(
      '.ag-s01-rich-text a[href^="https://"]:not([target="_blank"])'
    );
    const siteExternalLinks = document.querySelectorAll(".ag-o-link-tooltip");

    const unifiedLinks = [
      ...externalLinksnoTarget,
      ...externalLinksTarget,
      ...externalLinksnoTargetTwo,
      ...externalLinksTargetTwo,
      ...siteExternalLinks
    ];
    return unifiedLinks;
  }

  getPositionLink() {
    const externalLinks = this.getExternalLinks();
    externalLinks.forEach(link => {
      // Mostrar tooltip en mouseover (desktop)
      link.addEventListener("mouseover", event => {
        this.handleShowTooltip(event, link);
      });
      // Mostrar tooltip en touchstart (mobile)
      link.addEventListener("touchstart", event => {
        this.handleShowTooltip(event, link);
        const handleTouchOutside = e => {
          if (!link.contains(e.target) && !this.element.contains(e.target)) {
            this.hideTooltip();
            document.removeEventListener("touchend", handleTouchOutside);
          }
        };
        document.addEventListener("touchend", handleTouchOutside);
      });
      // Ocultar tooltip en mouseout (desktop)
      link.addEventListener("mouseout", () => {
        this.hideTooltip();
      });
    });
  }
  handleShowTooltip(event, link) {
    const position = link.getBoundingClientRect();
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
    const href = link.getAttribute("href");
    if (
      (href.startsWith("https://") || href.startsWith("http://")) &&
      !link.hasAttribute("target")
    ) {
      tooltipText = "Saldrás del sitio actual";
    } else {
      tooltipText = "Se abrirá en una nueva pestaña";
    }
    this.showTooltip(position, scrollTop, scrollLeft, tooltipText);
  }
  showTooltip(position, scrollTop, scrollLeft, text) {
    // Posicionar el tooltip
    this.element.textContent = text;
    this.element.style.top = `${position.bottom + scrollTop + 5}px`;
    this.element.style.left = `${position.left + scrollLeft}px`;
    this.element.style.opacity = "1";
  }
  hideTooltip() {
    // Ocultar el tooltip
    this.element.style.opacity = "0";
  }
}

new externalLinkTooltip(".ag-js-tooltip-external-link");
