class AccessibilityPanel {
  constructor(panelSelector) {
    this.panel = document.querySelector(panelSelector);
    if (!this.panel) {
      throw new Error(
        "Panel no encontrado. Verifica el selector proporcionado."
      );
    }

    this.triggerButton = this.panel.querySelector(".js-accessibility-trigger");
    this.content = this.panel.querySelector(".js-accessibility-panel-content");
    this.closeButtons = this.panel.querySelectorAll(
      ".js-accessibility-panel-close"
    );
    this.decreaseButton = this.panel.querySelector(".js-size-decrease");
    this.increaseButton = this.panel.querySelector(".js-size-increase");
    this.textValue = this.panel.querySelector(".js-size-value");
    this.resetButton = this.panel.querySelector(".js-size-reset");

    // Estado inicial
    this.textSize = 100;
    this.minTextSize = 100;
    this.maxTextSize = 130;

    this.init();
  }

  init() {
    this.triggerButton.addEventListener("click", () => this.togglePanel());
    this.closeButtons.forEach(button =>
      button.addEventListener("click", () => this.closePanel())
    );
    this.decreaseButton.addEventListener("click", () =>
      this.changeTextSize(-10)
    );
    this.increaseButton.addEventListener("click", () =>
      this.changeTextSize(10)
    );
    this.resetButton.addEventListener("click", () => this.resetTextSize());

    // Configurar estado inicial de accesibilidad
    this.updateAriaAttributes();
  }

  togglePanel() {
    const isOpen = this.triggerButton.getAttribute("aria-expanded") === "true";
    if (isOpen) {
      this.closePanel();
    } else {
      this.openPanel();
    }
  }

  openPanel() {
    this.triggerButton.setAttribute("aria-expanded", "true");
    this.content.setAttribute("aria-hidden", "false");
    this.panel.classList.add("is-open");
  }

  closePanel() {
    this.triggerButton.setAttribute("aria-expanded", "false");
    this.content.setAttribute("aria-hidden", "true");
    this.panel.classList.remove("is-open");
  }

  changeTextSize(delta) {
    const newSize = this.textSize + delta;

    if (newSize >= this.minTextSize && newSize <= this.maxTextSize) {
      this.textSize = newSize;
      this.textValue.textContent = `${this.textSize}%`;
      this.textValue.setAttribute("aria-live", "polite");
      document.documentElement.style.fontSize = `${this.textSize}%`;
    }
  }

  resetTextSize() {
    this.textSize = 100;
    this.textValue.textContent = `${this.textSize}%`;
    this.textValue.setAttribute("aria-live", "polite");
    document.documentElement.style.fontSize = `${this.textSize}%`;
  }

  updateAriaAttributes() {
    const isOpen = this.triggerButton.getAttribute("aria-expanded") === "true";
    this.content.setAttribute("aria-hidden", !isOpen);
  }
}

new AccessibilityPanel(".js-panel-accessibility");
