export function showHidePassword() {
  const fieldIcon = document.querySelector(".ag-js-toggle-pass");
  if (fieldIcon) {
    const toggleIcon = fieldIcon.querySelector(".ag-js-toggle-pass-ico");
    const input = fieldIcon.querySelector("input");
    toggleIcon.addEventListener("click", e => {
      e.preventDefault();
      const isPasswordVisible = input.type === "text";
      input.type = isPasswordVisible ? "password" : "text";
      toggleIcon.classList.toggle("i-eye", isPasswordVisible);
      toggleIcon.classList.toggle("i-eye-slash", !isPasswordVisible);
    });
  }
}

showHidePassword();
